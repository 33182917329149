import React, {Component} from 'react';
import '../assets/css/dashboard.css';
import {connect} from "react-redux";
import {
    reset,
    deleteAlbum,
    deleteTrack,
} from "../redux/actions/users/get-data-site";
import {toast} from "react-toastify";
import { withTranslation } from "react-i18next";
class DeleteAlbum extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.msg_album_delete && prevProps.msg_album_delete !== this.props.msg_album_delete) {
            toast(this.props.msg_album_delete);
            const {
                reset,
                handleClose,
            } = this.props;
            const self = this;
            clearTimeout(self.tmr);
            this.tmr = setTimeout(function () {
                reset();
                self.tmr = null;

                handleClose();
                window.location.href = self.props.path;
            }, 2000);
        }
    }
    Cancel = () => {
        const {
            handleClose
        } = this.props;

        handleClose();
    };
    onDelete = (albums) => {
        if(this.props.flag === false) {
            const data = {
                role_id: localStorage.id,
                albums: albums,
                // language: this.props.lang,
            };
            const {
                deleteAlbum,
            } = this.props;
            deleteAlbum(data);
        } else {
            const data = {
                role_id: localStorage.id,
                id: this.props.id,
                tracks: albums,
                // language: this.props.lang,
            };
            const {
                deleteTrack,
            } = this.props;
            deleteTrack(data);
        }
    };
    render() {
        const { t } = this.props;
        const showHideClassName = this.props.show ? "modal-b display-modal-block" : "modal-b display-modal-none";
        return (
            <div className={showHideClassName}>
                <section className="modal-article">
                    <div className="create-modal-header txt-20 justify-left col-white">{t("cancel")} {this.props.flag? t("tracks") : t("album") + " "} {t("registration")}</div>

                    <div className="pt-30 pb-30 txt-18 justify-center">{t("delete_album_modal_content")} {this.props.flag? t("tracks") : t("album")}?</div>

                    <div className="flex-grid2 modal-grid2-gaps modal-p">
                        <div className="btn-common mouse-cursor justify-center col-white" onClick={this.Cancel}>{t("cancel")}</div>
                        <div className="btn-common mouse-cursor justify-center col-white" onClick={() => this.onDelete(this.props.albums)}>{t("unregister")}</div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        msg_album_delete: state.users.msg_album_delete,
    }
};
export default connect(
    mapStateToProps,
    {
        reset,
        deleteAlbum,
        deleteTrack,
    }
)(withTranslation("translations")(DeleteAlbum));

