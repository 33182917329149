import React, {Component} from 'react';
import '../assets/css/dashboard.css';
import {connect} from "react-redux";
import {
    deleteHistory,
} from "../redux/actions/users/management";
import { withTranslation } from "react-i18next";
class DeleteHistory extends Component {
    Cancel = () => {
        const {
            handleClose
        } = this.props;
        handleClose();
    };
    onDelete = () => {
        const data = {
            role_id: localStorage.id,
            track_id: this.props.track_id,
            history_index: this.props.history_index,
        };
        const {
            deleteHistory
        } = this.props;
        deleteHistory(data);
    };
    render() {
        const { t } = this.props;
        const showHideClassName = this.props.show ? "modal-b display-modal-block" : "modal-b display-modal-none";
        return (
            <div className={showHideClassName}>
                <section className="modal-article">
                    <div className="create-modal-header txt-20 justify-left col-white">{t("delete_track_history")}</div>
                    <div className="pt-30 pb-30 txt-18 justify-center">{t("delete_record_modal_content")}</div>
                    <div className="flex-grid2 modal-grid2-gaps modal-p">
                        <div className="btn-common mouse-cursor justify-center col-white" onClick={this.Cancel}>{t("cancel")}</div>
                        <div className="btn-common mouse-cursor justify-center col-white" onClick={ this.onDelete }>{t("delete")}</div>
                    </div>
                </section>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
    }
};
export default connect(
    mapStateToProps,
    {
        deleteHistory,
    }
)(withTranslation("translations")(DeleteHistory));

