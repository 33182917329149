import React, { Component } from "react";
import { connect } from "react-redux";
import { reset, getAllUsers } from "../redux/actions/users/publisher";
import {
  resetList,
  getRegisteredAlbumList,
  searchRegisteredAlbum,
  assignAlbumsToUser,
  assignAllAlbumsToUser,
} from "../redux/actions/users/get-data-site";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteAlbum from "./modal-delete-album";
import { withTranslation } from "react-i18next";
import Select from "react-select";

class AssignAlbum extends Component {
  constructor(props) {
    super(props);

    this.tmr = null;
    this.state = {
      all_users: [],
      album_list: "",
      user_id: "",
      allChecked: false,
      new_checked: [],
      show: false,
      album_ids: [],
      search_id: "",

      current_page: 1,
      page_neighbours: 2,
      pagination: 10,
      page_num: "",
      searchQuery: "",
      filteredUsers: [],
      showSuggestions: false,
    };
  }
  componentDidMount() {
    const { getAllUsers } = this.props;

    if (getAllUsers) {
      getAllUsers({ role_id: localStorage.id });
    }
    this.onPageClick(1);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * Getting album list
     */
    if (
      this.props.get_registered_albums &&
      this.props.get_registered_albums !== prevProps.get_registered_albums
    ) {
      this.setState({
        album_list: this.props.get_registered_albums.list,
        page_num: this.props.get_registered_albums.page_num,
      });
    }
    if (
      this.props.msg_registered_albums &&
      this.props.msg_registered_albums !== prevProps.msg_registered_albums
    ) {
      toast(this.props.msg_registered_albums);
      const { resetList } = this.props;
      const self = this;
      clearTimeout(self.tmr);
      self.tmr = setTimeout(function () {
        resetList();
        self.tmr = null;
        self.setState({
          search_id: "",
        });
      }, 3000);
    }
    /**
     * Getting all Users
     */
    if (
      this.props.getAll_users &&
      this.props.getAll_users !== prevProps.getAll_users
    ) {
      this.setState({
        all_users: this.props.getAll_users,
        filteredUsers: this.props.getAll_users,
      });
    }
    if (this.props.msg_get_users !== prevProps.msg_get_users) {
      toast(this.props.msg_get_users);
      const { reset } = this.props;
      const self = this;
      clearTimeout(self.tmr);
      self.tmr = setTimeout(function () {
        reset();
        self.tmr = null;
      }, 3000);
    }
    /**
     *  Check all
     */
    if (this.state.allChecked !== prevState.allChecked) {
      const temp = [];
      const temp_ids = [];
      if (this.state.allChecked === true) {
        let list = this.state.album_list;
        Object.keys(list).map((item, key) => {
          temp.push(list[key]._id);
          temp_ids.push(list[key].album_id);
          return null;
        });
        this.setState({
          new_checked: temp,
          album_ids: temp_ids,
        });
      }
    }
    if (this.state.new_checked !== prevState.new_checked) {
      let temp = [];
      let list = this.state.album_list;
      Object.keys(list).map((item, key) => {
        temp.push(list[key]._id);
        return null;
      });
      if (
        this.state.new_checked.length > 0 &&
        this.state.new_checked.length === temp.length
      ) {
        this.setState({
          allChecked: true,
        });
      } else {
        this.setState({
          allChecked: false,
        });
      }
    }
    /**
     * Assigning Albums
     */
    if (
      this.props.msg_assign_albums &&
      this.props.msg_assign_albums !== prevProps.msg_assign_albums
    ) {
      toast(this.props.msg_assign_albums);
      const { resetList } = this.props;
      clearTimeout(this.tmr);
      this.tmr = setTimeout(function () {
        resetList();
        this.tmr = null;
      }, 3000);
      this.onPageClick(1);
    }
    if (
      this.props.msg_error_assign_albums &&
      this.props.msg_error_assign_albums !== prevProps.msg_error_assign_albums
    ) {
      toast(this.props.msg_error_assign_albums);
      const { resetList } = this.props;
      clearTimeout(this.tmr);
      this.tmr = setTimeout(function () {
        resetList();
        this.tmr = null;
      }, 3000);
    }
  }
  onPageClick = (item) => {
    this.setState({
      current_page: item,
    });
    const { getRegisteredAlbumList } = this.props;
    const data = {
      role_id: localStorage.id,
      current_page: item,
      page_neighbours: this.state.page_neighbours,
      pagination: this.state.pagination,
    };
    if (getRegisteredAlbumList) {
      getRegisteredAlbumList(data);
    }
    this.setState({
      allChecked: false,
      new_checked: [],
      album_ids: [],
    });
  };
  handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    this.setState({
      searchQuery: query,
      filteredUsers: this.state.all_users.filter((user) =>
        user.name.toLowerCase().includes(query)
      ),
      showSuggestions: true,
    });
  };

  handleSelectUser = (userId) => {
    this.setState({ user_id: userId, showSuggestions: false });
  };

  onSelectUser = (e) => {
    if (e.target.value !== "0") {
      this.setState({
        user_id: e.target.value,
      });
    } else {
      this.setState({
        user_id: "",
      });
    }
  };
  onCheckBox = (e, album_id) => {
    const { new_checked, album_ids } = this.state;
    const temp = JSON.parse(JSON.stringify(new_checked));
    const temp_ids = JSON.parse(JSON.stringify(album_ids));
    if (e.target.checked === true) {
      temp.push(e.target.id);
      temp_ids.push(album_id);
    } else {
      temp.splice(temp.indexOf(e.target.id), 1);
      temp_ids.splice(temp_ids.indexOf(album_id), 1);
    }

    this.setState({
      new_checked: temp,
      album_ids: temp_ids,
    });
  };
  onAllCheck = () => {
    this.setState({
      allChecked: !this.state.allChecked,
      new_checked: [],
      album_ids: [],
    });
    console.log("newChecked : "+this.state.new_checked);
    console.log("album Ids : "+this.state.album_ids);
  };
  onAssign = () => {
    const { assignAlbumsToUser } = this.props;
    if (assignAlbumsToUser) {
      const data = {
        role_id: localStorage.id,
        publisher_id: this.state.user_id,
        new_checked: this.state.new_checked,
        all:"no"
      };
      assignAlbumsToUser(data);
    }
  };

  onAssignAll=()=>{

    const { assignAlbumsToUser } = this.props;
    if (assignAlbumsToUser) {
      const data = {
        role_id: localStorage.id,
        publisher_id: this.state.user_id,
        all: "all"
      };
      assignAlbumsToUser(data);
    }
    // const {assignAllAlbumsToUser} = this.props;
    // if(assignAllAlbumsToUser){
    //   const data={
    //     role_id:localStorage.id,
    //     publisher_id:this.state.user_id
    //   };
    //   assignAllAlbumsToUser(data);
    // }
  }

  /**
   * Modal
   */
  onShowDeleteModal = () => {
    if (this.state.album_ids.length > 0) {
      this.setState({
        show: true,
      });
    }
  };
  hideDeleteModal = () => {
    this.setState({
      user_id: "",
      allChecked: false,
      new_checked: [],
      show: false,
      album_ids: [],
    });
  };

  /**
   * Searching
   */
  onAlbumId = (e) => {
    this.setState({
      [e.target.id]: e.target.value || "",
    });
  };
  onInputAlbumId = (code) => {
    if (code === 13) {
      if (this.state.search_id !== "") {
        const { searchRegisteredAlbum } = this.props;
        if (searchRegisteredAlbum) {
          searchRegisteredAlbum({
            role_id: localStorage.getItem("id"),
            album_id: this.state.search_id,
          });
        }
      } else {
        this.onPageClick(1);
      }
      this.setState({
        show: false,
        new_checked: [],
        album_ids: [],
        allChecked: false,
      });
    }
  };
  getUserDisplayName = () => {
    const { all_users, user_id, searchQuery } = this.state;
    if (Array.isArray(all_users) && all_users.length > 0) {
      const user = all_users.find((u) => u.id === user_id);
      return user ? user.name : searchQuery;
    }
    return "";
  };

  handleSelectUserChange = (selectedOption) => {
    this.setState({ user_id: selectedOption ? selectedOption.value : "" });
  };

  render() {
    const { t } = this.props;
    const pageArray = [];
    if (this.state.page_num) {
      for (
        let k = this.state.page_num.start_page;
        k <= this.state.page_num.end_page;
        k++
      ) {
        pageArray.push(k);
      }
    }
    const userOptions = this.state.all_users.map((user) => ({
      value: user.id,
      label: user.name,
    }));
    return (
      <>
        <div
          className="spinning-curtain"
          style={{ display: this.props.spinning ? "flex" : "none" }}
        >
          <div className="lds-dual-ring" />
        </div>
        <ToastContainer />
        <div className="col-darkBlue txt-30 txt-bold pb-20 pl-40">
          {t("album_list_per_user")}
        </div>
        <div className="form-bg">
          <div className="flex-space assign-unregister">
            <div className="justify-left publisher-album">
              <div className="justify-left">
                <div className="col-buttonAndLink">{t("publisher")}</div>
                <Select
                  styles={{
                    container: (base) => ({
                      ...base,
                      marginLeft: 10,
                      width: 300,
                    }),
                  }}
                  className="select-component" // Use custom class for styling if necessary
                  options={userOptions}
                  onChange={this.handleSelectUserChange}
                  placeholder="Search Publishers..."
                  isClearable={true} // Allows clearing the selection
                  isSearchable={true} // Allows searching
                  value={userOptions.find(
                    (option) => option.value === this.state.user_id
                  )}
                />
              </div>
              <div className="justify-left mobile-mt">
                <div
                  className="justify-center col-buttonAndLink ml-40"
                  style={{ marginRight: 10 }}
                >
                  {t("album_id")}
                </div>
                <div className="mr-id-10">
                  <input
                    id="search_id"
                    className="count mouse-cursor"
                    placeholder={t("album_id")}
                    value={this.state.search_id}
                    onChange={(e) => this.onAlbumId(e)}
                    onKeyUp={(e) => this.onInputAlbumId(e.keyCode)}
                  />
                </div>
              </div>
            </div>

            <div className="justify-left btn-mt">
            <div
                className="btn-common action assign mouse-cursor col-white"
                onClick={this.onAssignAll}
              >
                {t("assign_all")}
              </div>
              <div
                className="ml-15 btn-common action assign mouse-cursor col-white"
                onClick={this.onAssign}
              >
                {t("assign")}
              </div>
              <div
                className="ml-15 btn-common action assign mouse-cursor col-white"
                onClick={this.onShowDeleteModal}
              >
                {t("unregister")}
              </div>
            </div>
          </div>
          <div className="table-p">
            <table className="tList">
              <thead>
                <tr className="table-list">
                  <th>{t("no")}</th>
                  <th>{t("album_id")}</th>
                  <th>{t("name")}</th>
                  <th>{t("artists")}</th>
                  <th>{t("tracks")}</th>
                  <th>{t("registered_date")}</th>
                  <th>{t("image")}</th>
                  <th>
                    <label className="container-event">
                      <input
                        type="checkbox"
                        checked={this.state.allChecked}
                        onChange={this.onAllCheck}
                      />
                      <span className="checkMark" />
                    </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.album_list &&
                  this.state.album_list.map((item, key) => {
                    return (
                      <tr key={key} className="table-list">
                        <td>{key + 1}</td>
                        <td>{this.state.album_list[key].album_id}</td>
                        <td>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.album_list[key].name,
                            }}
                          />
                        </td>
                        <td>{this.state.album_list[key].artists.length}</td>
                        <td>{this.state.album_list[key].tracks.length}</td>
                        <td>
                          {new Date(
                            this.state.album_list[key].registered_date
                          ).toLocaleString()}
                        </td>
                        <td
                          className="mouse-cursor"
                          onClick={() =>
                            (window.location = this.state.album_list[key].path)
                          }
                        >
                          {this.state.album_list[key].thumbnail && (
                            <img
                              className="thumbnail-size"
                              src={this.state.album_list[key].thumbnail}
                              alt=""
                            />
                          )}
                        </td>
                        <td>
                          <label className="container-event" key={key}>
                            <input
                              id={this.state.album_list[key]._id}
                              type="checkbox"
                              checked={this.state.new_checked.includes(
                                this.state.album_list[key]._id
                              )}
                              onChange={(e) =>
                                this.onCheckBox(
                                  e,
                                  this.state.album_list[key].album_id
                                )
                              }
                            />
                            <span className="checkMark" />
                          </label>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {this.state.page_num && pageArray && pageArray.length > 0 && (
            <div className="help-center-align">
              <div
                className="product-btn justify-center"
                onClick={() => this.onPageClick(1)}
              >
                <svg
                  width="11"
                  height="15"
                  viewBox="0 0 11 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.60496 14.6383C9.42024 14.6383 9.23359 14.5779 9.07773 14.457L0.923018 8.02084C0.724826 7.86414 0.609375 7.62814 0.609375 7.37704C0.609375 7.12782 0.724826 6.88993 0.923018 6.73512L9.0431 0.332906C9.40485 0.047818 9.934 0.104458 10.2246 0.459402C10.5151 0.814346 10.4574 1.33355 10.0956 1.61863L2.79141 7.37704L10.1322 13.1713C10.4939 13.4564 10.5517 13.9756 10.2611 14.3305C10.0937 14.5326 9.85126 14.6383 9.60496 14.6383Z"
                    fill="black"
                    fillOpacity="0.65"
                  />
                </svg>
              </div>
              {this.state.page_num &&
                pageArray &&
                pageArray.map((item, key) => {
                  return (
                    <div
                      className={
                        this.state.current_page &&
                        this.state.current_page === item
                          ? "product-btn justify-center btn-search"
                          : "product-btn justify-center col-darkBlue"
                      }
                      key={key}
                      onClick={() => this.onPageClick(item)}
                    >
                      {item}
                    </div>
                  );
                })}
              <div
                className="product-btn justify-center"
                onClick={() => this.onPageClick(this.state.page_num.total_page)}
              >
                <svg
                  width="11"
                  height="15"
                  viewBox="0 0 11 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.39506 14.6383C1.57978 14.6383 1.76643 14.5779 1.92229 14.457L10.077 8.02084C10.2752 7.86414 10.3906 7.62814 10.3906 7.37704C10.3906 7.12782 10.2752 6.88993 10.077 6.73512L1.95692 0.332906C1.59518 0.047818 1.06603 0.104458 0.775474 0.459402C0.484922 0.814346 0.542647 1.33355 0.904394 1.61863L8.2086 7.37704L0.867834 13.1713C0.506087 13.4564 0.448362 13.9756 0.738914 14.3305C0.906319 14.5326 1.14877 14.6383 1.39506 14.6383Z"
                    fill="black"
                    fillOpacity="0.65"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>

        {/*  Unregister Modal  */}
        <DeleteAlbum
          show={this.state.show}
          handleClose={this.hideDeleteModal}
          albums={this.state.album_ids}
          flag={false}
          path={"/assign-album"}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinning: state.users.spinning,
    getAll_users: state.users.getAll_users,
    msg_get_users: state.users.msg_get_users,
    get_registered_albums: state.users.get_registered_albums,
    msg_registered_albums: state.users.msg_registered_albums,
    msg_assign_albums: state.users.msg_assign_albums,
    msg_error_assign_albums: state.users.msg_error_assign_albums,
  };
};
export default connect(mapStateToProps, {
  reset,
  getAllUsers,
  resetList,
  getRegisteredAlbumList,
  searchRegisteredAlbum,
  assignAlbumsToUser,
  assignAllAlbumsToUser,
})(withTranslation("translations")(AssignAlbum));
