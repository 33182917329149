import {createStore, applyMiddleware, compose} from "redux";
import thunk from "redux-thunk";
import {createLogger} from 'redux-logger';
import rootReducer from "../reducers/rootReducer";

const logger = createLogger({
    collapsed: true,
  });

const initialState = {};
const middleware = [thunk, logger];
const store = createStore(
    rootReducer,
    initialState,
    compose(
        applyMiddleware(...middleware)
    )
);

export { store };