import React, {Component} from 'react';
import { withTranslation } from "react-i18next";
class CountriesList extends Component{
    render(){
            const { t } = this.props;
        return (
            <>
                <option value="AF">{t("afghanistan")}</option>
                <option value="AX">{t("aland_islands")}</option>
                <option value="AL">{t("albania")}</option>
                <option value="DZ">{t("algeria")}</option>
                <option value="AS">{t("american_samoa")}</option>
                <option value="AD">{t("andorra")}</option>
                <option value="AO">{t("angola")}</option>
                <option value="AI">{t("anguilla")}</option>
                <option value="AQ">{t("antarctica")}</option>
                <option value="AG">{t("antigua_and_barbuda")}</option>
                <option value="AR">{t("argentina")}</option>
                <option value="AM">{t("armenia")}</option>
                <option value="AW">{t("aruba")}</option>
                <option value="AU">{t("australia")}</option>
                <option value="AT">{t("austria")}</option>
                <option value="AZ">{t("azerbaijan")}</option>
                <option value="BS">{t("bahamas")}</option>
                <option value="BH">{t("bahrain")}</option>
                <option value="BD">{t("bangladesh")}</option>
                <option value="BB">{t("barbados")}</option>
                <option value="BY">{t("belarus")}</option>
                <option value="BE">{t("belgium")}</option>
                <option value="BZ">{t("belize")}</option>
                <option value="BJ">{t("benin")}</option>
                <option value="BM">{t("bermuda")}</option>
                <option value="BT">{t("bhutan")}</option>
                <option value="BO">{t("bolivia_plurinationa_state_of")}</option>
                <option value="BQ">{t("bonaire_sint_eustatius_and_saba")}</option>
                <option value="BA">{t("bosnia_and_herzegovina")}</option>
                <option value="BW">{t("botswana")}</option>
                <option value="BV">{t("bouvet_island")}</option>
                <option value="BR">{t("brazil")}</option>
                <option value="IO">{t("british_indian_ocean_territory")}</option>
                <option value="BN">{t("brunei_darussalam")}</option>
                <option value="BG">{t("bulgaria")}</option>
                <option value="BF">{t("burkina_faso")}</option>
                <option value="BI">{t("burundi")}</option>
                <option value="KH">{t("cambodia")}</option>
                <option value="CM">{t("cameroon")}</option>
                <option value="CA">{t("canada")}</option>
                <option value="CV">{t("cape_verde")}</option>
                <option value="KY">{t("cayman_islands")}</option>
                <option value="CF">{t("central_african_republic")}</option>
                <option value="TD">{t("chad")}</option>
                <option value="CL">{t("chile")}</option>
                <option value="CN">{t("china")}</option>
                <option value="CX">{t("christmas_island")}</option>
                <option value="CC">{t("cocos_keeling_islands")}</option>
                <option value="CO">{t("colombia")}</option>
                <option value="KM">{t("comoros")}</option>
                <option value="CG">{t("congo")}</option>
                <option value="CD">{t("congo_the_democratic_republic_of_the")}</option>
                <option value="CK">{t("cook_islands")}</option>
                <option value="CR">{t("costa_rica")}</option>
                <option value="CI">{t("cote_d_Ivoire")}</option>
                <option value="HR">{t("croatia")}</option>
                <option value="CU">{t("cuba")}</option>
                <option value="CW">{t("curacao")}</option>
                <option value="CY">{t("cyprus")}</option>
                <option value="CZ">{t("czech_republic")}</option>
                <option value="DK">{t("denmark")}</option>
                <option value="DJ">{t("djibouti")}</option>
                <option value="DM">{t("dominica")}</option>
                <option value="DO">{t("dominican_republic")}</option>
                <option value="EC">{t("ecuador")}</option>
                <option value="EG">{t("egypt")}</option>
                <option value="SV">{t("el_salvador")}</option>
                <option value="GQ">{t("equatorial_guinea")}</option>
                <option value="ER">{t("eritrea")}</option>
                <option value="EE">{t("estonia")}</option>
                <option value="ET">{t("ethiopia")}</option>
                <option value="FK">{t("falkland_islands_malvinas")}</option>
                <option value="FO">{t("faroe_islands")}</option>
                <option value="FJ">{t("fiji")}</option>
                <option value="FI">{t("finland")}</option>
                <option value="FR">{t("france")}</option>
                <option value="GF">{t("french_guiana")}</option>
                <option value="PF">{t("french_polynesia")}</option>
                <option value="TF">{t("french_southern_territories")}</option>
                <option value="GA">{t("gabon")}</option>
                <option value="GM">{t("gambia")}</option>
                <option value="GE">{t("georgia")}</option>
                <option value="DE">{t("germany")}</option>
                <option value="GH">{t("ghana")}</option>
                <option value="GI">{t("gibraltar")}</option>
                <option value="GR">{t("greece")}</option>
                <option value="GL">{t("greenland")}</option>
                <option value="GD">{t("grenada")}</option>
                <option value="GP">{t("guadeloupe")}</option>
                <option value="GU">{t("guam")}</option>
                <option value="GT">{t("guatemala")}</option>
                <option value="GG">{t("guernsey")}</option>
                <option value="GN">{t("guinea")}</option>
                <option value="GW">{t("guinea_bissau")}</option>
                <option value="GY">{t("guyana")}</option>
                <option value="HT">{t("haiti")}</option>
                <option value="HM">{t("heard_island_and_mcdonald_islands")}</option>
                <option value="VA">{t("holy_see_vatican_city_state")}</option>
                <option value="HN">{t("honduras")}</option>
                <option value="HK">{t("hong_kong")}</option>
                <option value="HU">{t("hungary")}</option>
                <option value="IS">{t("iceland")}</option>
                <option value="IN">{t("india")}</option>
                <option value="ID">{t("indonesia")}</option>
                <option value="IR">{t("iran_islamic_republic_of")}</option>
                <option value="IQ">{t("iraq")}</option>
                <option value="IE">{t("ireland")}</option>
                <option value="IM">{t("isle_of_man")}</option>
                <option value="IL">{t("israel")}</option>
                <option value="IT">{t("italy")}</option>
                <option value="JM">{t("jamaica")}</option>
                <option value="JP">{t("japan")}</option>
                <option value="JE">{t("jersey")}</option>
                <option value="JO">{t("jordan")}</option>
                <option value="KZ">{t("kazakhstan")}</option>
                <option value="KE">{t("kenya")}</option>
                <option value="KI">{t("kiribati")}</option>
                <option value="KP">{t("korea_democratic_people_republic_of")}</option>
                <option value="KR">{t("korea_republic_of")}</option>
                <option value="KW">{t("kuwait")}</option>
                <option value="KG">{t("kyrgyzstan")}</option>
                <option value="LA">{t("lao_people_democratic_republic")}</option>
                <option value="LV">{t("latvia")}</option>
                <option value="LB">{t("lebanon")}</option>
                <option value="LS">{t("lesotho")}</option>
                <option value="LR">{t("liberia")}</option>
                <option value="LY">{t("libya")}</option>
                <option value="LI">{t("liechtenstein")}</option>
                <option value="LT">{t("lithuania")}</option>
                <option value="LU">{t("luxembourg")}</option>
                <option value="MO">{t("macao")}</option>
                <option value="MK">{t("macedonia_the_former_yugoslav_republic_of")}</option>
                <option value="MG">{t("madagascar")}</option>
                <option value="MW">{t("malawi")}</option>
                <option value="MY">{t("malaysia")}</option>
                <option value="MV">{t("maldives")}</option>
                <option value="ML">{t("mali")}</option>
                <option value="MT">{t("malta")}</option>
                <option value="MH">{t("marshall_islands")}</option>
                <option value="MQ">{t("martinique")}</option>
                <option value="MR">{t("mauritania")}</option>
                <option value="MU">{t("mauritius")}</option>
                <option value="YT">{t("mayotte")}</option>
                <option value="MX">{t("mexico")}</option>
                <option value="FM">{t("micronesia_federated_states_of")}</option>
                <option value="MD">{t("moldova_republic_of")}</option>
                <option value="MC">{t("monaco")}</option>
                <option value="MN">{t("mongolia")}</option>
                <option value="ME">{t("montenegro")}</option>
                <option value="MS">{t("montserrat")}</option>
                <option value="MA">{t("morocco")}</option>
                <option value="MZ">{t("mozambique")}</option>
                <option value="MM">{t("myanmar")}</option>
                <option value="NA">{t("namibia")}</option>
                <option value="NR">{t("nauru")}</option>
                <option value="NP">{t("nepal")}</option>
                <option value="NL">{t("netherlands")}</option>
                <option value="NC">{t("new_caledonia")}</option>
                <option value="NZ">{t("new_zealand")}</option>
                <option value="NI">{t("nicaragua")}</option>
                <option value="NE">{t("niger")}</option>
                <option value="NG">{t("nigeria")}</option>
                <option value="NU">{t("niue")}</option>
                <option value="NF">{t("norfolk_island")}</option>
                <option value="MP">{t("northern_mariana_islands")}</option>
                <option value="NO">{t("norway")}</option>
                <option value="OM">{t("oman")}</option>
                <option value="PK">{t("pakistan")}</option>
                <option value="PW">{t("palau")}</option>
                <option value="PS">{t("palestinian_territory_occupied")}</option>
                <option value="PA">{t("panama")}</option>
                <option value="PG">{t("papua_new_guinea")}</option>
                <option value="PY">{t("paraguay")}</option>
                <option value="PE">{t("peru")}</option>
                <option value="PH">{t("philippines")}</option>
                <option value="PN">{t("pitcairn")}</option>
                <option value="PL">{t("poland")}</option>
                <option value="PT">{t("portugal")}</option>
                <option value="PR">{t("puerto_rico")}</option>
                <option value="QA">{t("qatar")}</option>
                <option value="RE">{t("reunion")}</option>
                <option value="RO">{t("romania")}</option>
                <option value="RU">{t("russian_federation")}</option>
                <option value="RW">{t("rwanda")}</option>
                <option value="BL">{t("saint_barthelemy")}</option>
                <option value="SH">{t("saint_helena_ascension_and_tristan_da_cunha")}</option>
                <option value="KN">{t("saint_kitts_and_nevis")}</option>
                <option value="LC">{t("saint_lucia")}</option>
                <option value="MF">{t("saint_martin_french_part")}</option>
                <option value="PM">{t("saint_pierre_and_miquelon")}</option>
                <option value="VC">{t("saint_vincent_and_the_grenadines")}</option>
                <option value="WS">{t("samoa")}</option>
                <option value="SM">{t("san_marino")}</option>
                <option value="ST">{t("sao_tome_and_principe")}</option>
                <option value="SA">{t("saudi_arabia")}</option>
                <option value="SN">{t("senegal")}</option>
                <option value="RS">{t("serbia")}</option>
                <option value="SC">{t("seychelles")}</option>
                <option value="SL">{t("sierra_leone")}</option>
                <option value="SG">{t("singapore")}</option>
                <option value="SX">{t("sint_maarten_dutch_part")}</option>
                <option value="SK">{t("slovakia")}</option>
                <option value="SI">{t("slovenia")}</option>
                <option value="SB">{t("solomon_islands")}</option>
                <option value="SO">{t("somalia")}</option>
                <option value="ZA">{t("south_africa")}</option>
                <option value="GS">{t("south_georgia_and_the_south_sandwich_islands")}</option>
                <option value="SS">{t("south_sudan")}</option>
                <option value="ES">{t("spain")}</option>
                <option value="LK">{t("sri_lanka")}</option>
                <option value="SD">{t("sudan")}</option>
                <option value="SR">{t("suriname")}</option>
                <option value="SJ">{t("svalbard_and_jan_mayen")}</option>
                <option value="SZ">{t("swaziland")}</option>
                <option value="SE">{t("sweden")}</option>
                <option value="CH">{t("switzerland")}</option>
                <option value="SY">{t("syrian_arab_republic")}</option>
                <option value="TW">{t("taiwan_province_of_china")}</option>
                <option value="TJ">{t("tajikistan")}</option>
                <option value="TZ">{t("tanzania_united_republic_of")}</option>
                <option value="TH">{t("thailand")}</option>
                <option value="TL">{t("timor_leste")}</option>
                <option value="TG">{t("togo")}</option>
                <option value="TK">{t("tokelau")}</option>
                <option value="TO">{t("tonga")}</option>
                <option value="TT">{t("trinidad_and_tobago")}</option>
                <option value="TN">{t("tunisia")}</option>
                <option value="TR">{t("turkey")}</option>
                <option value="TM">{t("turkmenistan")}</option>
                <option value="TC">{t("turks_and_caicos_islands")}</option>
                <option value="TV">{t("tuvalu")}</option>
                <option value="UG">{t("uganda")}</option>
                <option value="UA">{t("ukraine")}</option>
                <option value="AE">{t("united_arab_emirates")}</option>
                <option value="GB">{t("united_kingdom")}</option>
                <option value="US">{t("united_states")}</option>
                <option value="UM">{t("united_states_minor_outlying_islands")}</option>
                <option value="UY">{t("uruguay")}</option>
                <option value="UZ">{t("uzbekistan")}</option>
                <option value="VU">{t("vanuatu")}</option>
                <option value="VE">{t("venezuela_bolivarian_republic_of")}</option>
                <option value="VN">{t("viet_nam")}</option>
                <option value="VG">{t("virgin_islands_british")}</option>
                <option value="VI">{t("virgin_islands_us")}</option>
                <option value="WF">{t("wallis_and_futuna")}</option>
                <option value="EH">{t("western_sahara")}</option>
                <option value="YE">{t("yemen")}</option>
                <option value="ZM">{t("zambia")}</option>
                <option value="ZW">{t("zimbabwe")}</option>
            </>
        );
    };
}
export default withTranslation("translations")(CountriesList);
