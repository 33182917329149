import React, { Component } from "react";
import { connect } from "react-redux";
import "../assets/css/dashboard.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  reset,
  resetManagement,
  getTrackListById,
  addPaymentInfo,
  updateTrackPrice,
} from "../redux/actions/users/management";
import {
  deleteTrack,
  updateAlbum,
  getAllArtists,
} from "../redux/actions/users/get-data-site";
import DeleteHistory from "./delete-history";
import { withTranslation } from "react-i18next";
import Prompter from "./Prompter";
import { decodeHtml } from "../utils";
import Select from "react-select";
import updateIcon from "../assets/images/update.svg"
import bin28 from "../assets/images/bin-28.svg"
import editIcon from "../assets/images/edit.svg"
import viewIcon from "../assets/images/view.svg"

const config = require("../config/config");

class PublisherTracks extends Component {
  constructor(props) {
    super(props);
    this.tmr = null;
    this.state = {
      listById: "",
      user_id: "",
      album_id: "",
      flag_edit: "",
      price: "",
      isrc: "",
      currency: "",
      table_show: true,
      detail_show: false,
      detail_item: "",

      show: false,
      history_index: "",

      showAddArtist: false,
      newArtistId: null,
      newArtistName: null,
      suggestions: [],
      showSuggestions: false,
    };
  }
  componentDidMount() {
    this.getInitialTrackList();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.get_track_list &&
      this.props.get_track_list !== prevProps.get_track_list
    ) {
      this.setState({
        listById: this.props.get_track_list,
        currency: this.props.get_track_list.currency,
      });
    }
    if (
      this.props.msg_error_tracks &&
      this.props.msg_error_tracks !== prevProps.msg_error_tracks
    ) {
      toast(this.props.msg_error_tracks);
      const { reset } = this.props;
      clearTimeout(this.tmr);
      this.tmr = setTimeout(function () {
        reset();
        this.tmr = null;
      }, 1500);
    }
    if (
      this.props.msg_track_update &&
      this.props.msg_track_update !== prevProps.msg_track_update
    ) {
      toast(this.props.msg_track_update);
      const { reset } = this.props;
      clearTimeout(this.tmr);
      this.tmr = setTimeout(function () {
        reset();
        this.tmr = null;
      }, 1500);
      this.getInitialTrackList();
    }
    if (
      this.props.msg_delete_history &&
      prevProps.msg_delete_history !== this.props.msg_delete_history
    ) {
      toast(this.props.msg_delete_history);
      const { resetManagement } = this.props;
      let self = this;
      clearTimeout(self.tmr);
      this.tmr = setTimeout(function () {
        let detail_item = self.state.detail_item;
        let list = detail_item.details;
        list.splice(self.state.history_index, 1);
        detail_item.details = list;
        self.setState({
          detail_item: detail_item,
        });
        resetManagement();
        self.tmr = null;
        self.handleClose();
        self.getInitialTrackList();
      }, 1000);
    }
    if (
      this.props.msg_err_delete_history &&
      prevProps.msg_err_delete_history !== this.props.msg_err_delete_history
    ) {
      toast(this.props.msg_err_delete_history);
      const { resetManagement } = this.props;
      let self = this;
      clearTimeout(self.tmr);
      this.tmr = setTimeout(function () {
        resetManagement();
        self.tmr = null;
      }, 1000);
    }
  }
  getInitialTrackList = () => {
    let path = this.props.match.params.slug;
    let array = path.split("-");
    const { getTrackListById, getAllArtists } = this.props;
    if (getTrackListById) {
      const data = {
        user_id: array[0],
        album_id: array[1],
        role: localStorage.getItem("role"),
      };
      getTrackListById(data);
      this.setState({
        user_id: array[0],
        album_id: array[1],
      });
    }

    getAllArtists({ role_id: localStorage.id });
  };
  onChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value || 0,
    });
  };
  onChangeArtistInput = (e) => {
    const { id, value } = e.target;
    this.setState({ [id]: value }, () => {
      if (value.length > 0) {
        this.filterSuggestions(value, id);
      } else {
        this.setState({ suggestions: [], showSuggestions: false });
      }
    });
  };
  onEdit = (flag, price, isrc) => {
    if(!price){
      price = 
      this.state.listById.album &&
        this.state.listById.album.amount_per_one
      ? this.state.listById.album.amount_per_one:0;
    }
    this.setState({
      flag_edit: flag,
      price: price,
      isrc: isrc,
    });
  };
  onUpdate = (updatePreviousDetails) => {
    if (this.state.isrc?.length && !this.isValidISRC(this.state.isrc)) {
      alert("Please enter valid ISRC number");
      return;
    }

    const { updateTrackPrice } = this.props;
    if (updateTrackPrice) {
      const data = {
        role_id: localStorage.id,
        track_url: this.state.flag_edit,
        album_id: this.state.album_id,
        price: this.state.price,
        isrc: this.state.isrc,
        updatePreviousDetails,
      };
      updateTrackPrice(data);
    }
    this.setState({
      flag_edit: "",
      price: "",
      isrc: "",
    });
  };

  filterSuggestions = (value, id) => {
    const { allArtists } = this.props;
    if (allArtists && allArtists.length > 0) {
      const inputValue = value.trim().toLowerCase();
      let suggestions = [];

      if (id === "newArtistId") {
        suggestions = allArtists.filter((artist) =>
          artist.id.toString().startsWith(inputValue)
        );
      } else {
        suggestions = allArtists.filter(
          (artist) =>
            artist.title && artist.title.toLowerCase().includes(inputValue)
        );
      }

      this.setState({ suggestions, showSuggestions: true });
    }
  };

  isValidISRC(isrc) {
    // Regex pattern to match a valid ISRC number
    const regex = /^[a-zA-Z]{2}[0-9a-zA-Z]{3}\d{2}\d{5}$/;

    // Test the input string against the regex pattern
    return regex.test(isrc);
  }

  /*********** extra **********/
  // onExtraClick = (url) => {
  //     let path = this.props.match.params.slug;
  //     let array = path.split("-");
  //     const {
  //         addPaymentInfo
  //     } = this.props;
  //     if(addPaymentInfo) {
  //         const data = {
  //             album_id: array[1],//this.state.album_id,
  //             track_url: url,//this.state.flag_edit,
  //             platform: 'Android',
  //             version: '1.23.5',
  //         };
  //         addPaymentInfo(data);
  //     }
  // };
  /*********** extra **********/
  onShowTableContents = () => {
    this.setState({
      table_show: !this.state.table_show,
    });
  };
  onShowDetail = (item) => {
    this.setState({
      table_show: false,
      detail_show: true,
      detail_item: item,
    });
  };
  onInputAlbumId = (code) => {
    if (code === 13) {
      this.onUpdate();
    }
  };
  onInputArtist = (code) => {
    if (code === 13) {
      this.onUpdateAlbum();
    }
  };
  onUpdateAlbum = () => {
    const { updateAlbum } = this.props;
    if (updateAlbum) {
      const data = {
        role_id: localStorage.id,
        album_id: this.state.album_id,
        artists: [
          {
            id: this.state.newArtistId,
            title: this.state.newArtistName,
          },
        ],
      };
      updateAlbum(data, () => {
        this.getInitialTrackList();
        this.setState({
          showAddArtist: false,
          newArtistId: null,
          newArtistName: null,
        });
      });
    }
  };
  selectArtist = (artist) => {
    this.setState({
      newArtistId: artist.id.toString(),
      newArtistName: artist.title,
      suggestions: [],
      showSuggestions: false,
    });
  };
  /**
   * Delete history modal
   */
  handleOpen = (key) => {
    this.setState({
      show: true,
      history_index: key,
    });
  };
  handleClose = () => {
    this.setState({
      show: false,
      history_index: "",
    });
  };
  onDeleteTrack = (item) => {
    const { deleteTrack } = this.props;
    if (deleteTrack) {
      const data = {
        role_id: localStorage.id,
        tracks: [item.url],
        id: this.state.album_id,
      };
      deleteTrack(data, () => {
        this.getInitialTrackList();
      });
    }
  };
  exportTableToCSV = (filename) => {
    let csv = [];
    let rows = document.querySelectorAll("table tr");
    const imageColumnIndex = 4; // The index of the image column
    const viewColumnIndex = 8; // The index of the view column
    for (let i = 0; i < rows.length; i++) {
      let row = [],
        cols = rows[i].querySelectorAll("td, th");
      for (let j = 0; j < cols.length; j++) {
        if (j === imageColumnIndex || j === viewColumnIndex) continue; // Skip the image column
        row.push(decodeHtml(cols[j].innerText));
      }
      csv.push(row.join(","));
    }
    this.downloadCSV(csv.join("\n"), filename);
  };
  downloadCSV = (csv, filename) => {
    let csvFile;
    let downloadLink;
    csvFile = new Blob(["\ufeff" + csv], {
      type: "text/csv;charset=charset=utf-8",
    });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  handleArtistChange = (selectedOption) => {
    this.setState({
      newArtistId: selectedOption ? selectedOption.value : null,
      newArtistName: selectedOption
        ? selectedOption.label.split(" (")[0]
        : null, // Extract the artist name without the ID
    });
  };

  render() {
    const { t } = this.props;
    const { newArtistId, newArtistName, suggestions, showSuggestions } =
      this.state;

    const artistOptions = this.props.allArtists.map((artist) => ({
      value: artist.id,
      label: `${artist.title} (${artist.id})`,
    }));

    const trigger = (
      <div className="mr-10 mouse-cursor icon-flex tooltip">
        <img
          className="icon-size"
          src={updateIcon}
          alt=""
        />
        <span className="tooltiptext">{t("update_track_payment")}</span>
      </div>
    );
    const deleteTrigger = (
      <div className="mr-10 mouse-cursor action assign icon-flex tooltip">
        <img
          className="icon-size"
          src={bin28} // user-xmark-regular
          alt=""
        />
        <span className="tooltiptext">{t("delete_record_modal_content")}</span>
      </div>
    );
    return (
      <>
        <div
          className="spinning-curtain"
          style={{ display: this.props.spinning ? "flex" : "none" }}
        >
          <div className="lds-dual-ring" />
        </div>
        <ToastContainer />
        <div className="col-darkBlue txt-30 txt-bold pb-20 pl-40">
          {t("track_list")}
        </div>
        <div className="form-bg">
          {this.state.listById && (
            <div className="flex-grid2 album-grid">
              <div className="album-grid-mt">
                <img
                  className="album-img mouse-cursor"
                  src={this.state.listById.album.full_thumbnail}
                  onClick={() =>
                    (window.location = this.state.listById.album.path)
                  }
                  alt=""
                />
              </div>
              <div className="album-grid-mt justify-center col-darkBlue">
                <div>
                  <div className="justify-center">
                    <img
                      className="album-thumbnail-img mouse-cursor"
                      src={this.state.listById.album.thumbnail}
                      onClick={() =>
                        (window.location = this.state.listById.album.path)
                      }
                      alt=""
                    />
                  </div>
                  <div className="pt-10 justify-center txt-bold txt-20">
                    {t("title")}:
                  </div>
                  <div className="justify-center">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.listById.album.name,
                      }}
                    />
                  </div>
                  <div className="pt-10 justify-center txt-bold txt-20">
                    {t("artists")}:
                  </div>
                  <table className="tList">
                    <thead>
                      <tr className="table-list album">
                        <th>{t("no")}</th>
                        <th>{t("id")}</th>
                        <th>{t("name")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.showAddArtist
                        ? null
                        : this.state.listById &&
                          this.state.listById.album.artists != null &&
                          this.state.listById.album.artists.length
                        ? this.state.listById.album.artists.map((item, key) => {
                            return (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{item.id}</td>
                                <td>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.title,
                                    }}
                                  />
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                  {this.state.showAddArtist && (
                    <Select
                      options={artistOptions}
                      onChange={this.handleArtistChange}
                      placeholder="Select an Artist"
                      isSearchable={true}
                      value={artistOptions.find(
                        (option) => option.value === this.state.newArtistId
                      )}
                    />
                  )}

                  <div>
                    <div className="flex-space justify-right mt-id-10">
                      <div
                        className="btn-common action assign mouse-cursor col-white"
                        onClick={() => {
                          if (this.state.showAddArtist) {
                            this.onUpdateAlbum();
                          } else {
                            this.setState({ showAddArtist: true });
                          }
                        }}
                      >
                        {!this.state.listById ||
                        this.state.listById.album.artists == null ||
                        !this.state.listById.album.artists.length
                          ? t("add_artist")
                          : t("edit_artist")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div>
            <div className="pt-30 pb-10 justify-center col-paragraphBg txt-20">
              {t("tracks")}
            </div>
            <div>
              <div className="flex-space justify-right mt-id-10">
                <div
                  className="btn-common action assign mouse-cursor col-white"
                  onClick={() => this.exportTableToCSV("tracks.csv")}
                >
                  {t("csv")}
                </div>
              </div>
            </div>
          </div>
          <div className="table-p">
            <table className="tList">
              <thead className="track-list">
                <tr
                  className="table-list album mouse-cursor"
                  onClick={this.onShowTableContents}
                >
                  <th>{t("no")}</th>
                  <th>{t("title")}</th>
                  <th>{t("url")}</th>
                  <th>{t("played")}</th>
                  <th>{t("current_price")}</th>
                  <th>{t("total_amount")}</th>
                  <th>{t("isrc")}</th>
                  <th>{t("action")}</th>
                </tr>
              </thead>
              {this.state.table_show && (
                <tbody>
                  {this.state.listById &&
                    this.state.listById.tracks.map((item, key) => {
                      return (
                        <tr key={key} className="table-list album">
                          <td>{key + 1}</td>
                          <td>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.name }}
                            />
                          </td>
                          <td>{item.url}</td>
                          <td>
                            {item.download === "" || item.download === null
                              ? 0
                              : item.download}
                          </td>
                          <td>
                            {this.state.flag_edit === item.url ? (
                              <input
                                id="price"
                                type="number"
                                className="price-paid"
                                value={this.state.price}
                                onChange={(e) => this.onChange(e)}
                                onKeyUp={(e) => this.onInputAlbumId(e.keyCode)}
                                style={{ marginTop: 25 }}
                              />
                            ) : (
                              (item.amount_per_one
                                ? item.amount_per_one
                                : this.state.listById.album &&
                                  this.state.listById.album.amount_per_one
                                ? this.state.listById.album.amount_per_one
                                : 0) +
                              (this.state.currency
                                ? this.state.currency
                                : config.SYMBOL[0])
                            )}
                          </td>
                          <td>
                            {(item.total_amount
                              ? item.total_amount.toFixed(2)
                              : 0) +
                              (this.state.currency
                                ? this.state.currency
                                : config.SYMBOL[0])}
                          </td>
                          <td>
                            {this.state.flag_edit === item.url ? (
                              <input
                                id="isrc"
                                type="text"
                                className="isrc"
                                value={this.state.isrc || ""}
                                onChange={(e) => this.onChange(e)}
                                onKeyUp={(e) => this.onInputAlbumId(e.keyCode)}
                                style={{ marginTop: 25 }}
                                placeholder="USUM71311202"
                              />
                            ) : (
                              item.isrc || ""
                            )}
                          </td>
                          <td className="flex-space">
                            {this.state.flag_edit === item.url ? (
                              <Prompter
                                trigger={trigger}
                                title={t("important") + "!"}
                                message={t("how_to_update")}
                                buttons={[
                                  {
                                    title: t("from_now"),
                                    onClick: () => this.onUpdate(),
                                    className:
                                      "btn-common action assign mouse-cursor col-white",
                                    // style: { backgroundColor: "red" },
                                  },
                                  {
                                    title: t("since_the_beginning"),
                                    onClick: () => this.onUpdate(true),
                                    className:
                                      "btn-common action assign mouse-cursor col-white",
                                    // style: { backgroundColor: "blue" },
                                  },
                                ]}
                              />
                            ) : (
                              <div
                                className="mr-10 mouse-cursor icon-flex tooltip"
                                onClick={(e) =>
                                  this.onEdit(
                                    item.url,
                                    item.amount_per_one,
                                    item.isrc
                                  )
                                }
                              >
                                <img
                                  className="icon-size"
                                  src={editIcon}
                                  alt=""
                                />
                                <span className="tooltiptext">
                                  {t("edit_track")}
                                </span>
                              </div>
                            )}

                            <Prompter
                              trigger={deleteTrigger}
                              title={t("important") + "!"}
                              message={t("delete_record_modal_content")}
                              buttons={[
                                {
                                  title: t("delete"),
                                  onClick: () => this.onDeleteTrack(item),
                                  className:
                                    "btn-common action assign mouse-cursor col-white",
                                  // style: { backgroundColor: "red" },
                                },
                                {
                                  title: t("cancel"),
                                  className:
                                    "btn-common action assign mouse-cursor col-white",
                                  // style: { backgroundColor: "blue" },
                                },
                              ]}
                            />

                            {/*extra click*/}
                            {/*<div*/}
                            {/*    className="mr-10 mouse-cursor icon-flex tooltip"*/}
                            {/*    onClick={() => this.onExtraClick(item.url)}*/}
                            {/*>*/}
                            {/*    <img*/}
                            {/*        className="icon-size"*/}
                            {/*        src={require("../assets/images/view.svg")}*/}
                            {/*        alt="" />*/}
                            {/*    <span className="tooltiptext">TEST BUTTON for recording the price per track</span>*/}
                            {/*</div>*/}
                            {/*View*/}
                            <div
                              className="mr-10 mouse-cursor icon-flex tooltip"
                              onClick={(e) => this.onShowDetail(item)}
                            >
                              <img
                                className="icon-size"
                                src={viewIcon}
                                alt=""
                              />
                              <span className="tooltiptext">
                                {t("view_detailed_info")}
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              )}
            </table>
          </div>
          {/* Detail Show*/}
          {this.state.detail_show && (
            <>
              <div style={{ marginTop: 70 }}>
                <div className="pt-20 pb-20 justify-center col-selected-bg txt-20">
                  {t("detailed_view_track") + " "} "
                  {this.state.detail_item.name}"
                </div>
              </div>
              <div className="mt-30 justify-center col-darkBlue txt-bold txt-20">
                {t("track_history")}
              </div>
              <div className="table-p">
                <table className="tList">
                  <thead className="track-list">
                    <tr className="table-list album">
                      <th>{t("no")}</th>
                      <th>{t("country")}</th>
                      <th>{t("ip_address")}</th>
                      <th>{t("platform")}</th>
                      <th>{t("version")}</th>
                      <th>{t("price_per_played_track")}</th>
                      <th>{t("played_date")}</th>
                      {/* <th>{t("action")}</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.detail_item.details.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td style={{ paddingLeft: 20 }}>{key + 1}</td>
                          <td>{item.country}</td>
                          <td>{item.ip_address}</td>
                          <td>{item.platform ? item.platform : ""}</td>
                          <td>{item.version ? item.version : ""}</td>
                          <td>
                            {item.price_per_track +
                              (this.state.currency
                                ? this.state.currency
                                : config.SYMBOL[0])}
                          </td>
                          <td>
                            {new Date(item.played_date).toLocaleString([], {
                              year: "numeric",
                              month: "short",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </td>
                          {/* <td>
                            <div
                              className="mouse-cursor icon-flex tooltip"
                              onClick={() => this.handleOpen(key)}
                            >
                              <img
                                className="icon-size"
                                src={require("../assets/images/bin-28.svg")}
                                alt=""
                              />
                              <span className="tooltiptext history">
                                {t("delete_publisher")}
                              </span>
                            </div>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
        {/*  Modal  */}
        <DeleteHistory
          show={this.state.show}
          track_id={this.state.detail_item && this.state.detail_item._id}
          history_index={this.state.history_index}
          handleClose={this.handleClose}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinning: state.users.spinning,
    get_track_list: state.users.get_track_list,
    msg_error_tracks: state.users.msg_error_tracks,
    msg_err_delete_history: state.users.msg_err_delete_history,
    msg_delete_history: state.users.msg_delete_history,

    msg_track_update: state.users.msg_track_update,

    allArtists: state.users.allArtists,
  };
};
export default connect(mapStateToProps, {
  reset,
  resetManagement,
  getTrackListById,
  updateTrackPrice,
  deleteTrack,
  updateAlbum,
  getAllArtists,
  /**
   * Extra
   */
  addPaymentInfo,
})(withTranslation("translations")(PublisherTracks));
